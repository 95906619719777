<template>
    <div class="home page-fade-in">
        <div class="banner">
        </div>
        <div class="home-inner-wrap">
            <div class="about">
                <div class="card">
                    <div class="text">
                        <h1 class="title">关于我们</h1>
                        <p class="summary p1">
                            厦门利成智能科技有限公司是金利集团控股企业，为政府招商引进企业。公司以智慧城市综合服务商为战略定位，主营业务涵盖：建筑智能化及智能安防工程的规划、设计与施工，物联网技术，智慧政务信息化平台及智慧社区信息化管理平台的研发、生产与销售。
                        </p>
                        <p class="summary p2">
                            公司拥有信息系统集成与服务、电子与智能化工程专业承包等多项行业资质，获得：全国智能建筑行业优秀企业称号，中国智慧城市建设推荐品牌，安防百强企业以及高新技术企业……
                        </p>
                        <router-link active-class="active" class="more" to="/about">查看更多</router-link>
                    </div>
                    <div class="image">
                        <img alt="" src="../assets/image/pic_aboutus.png">
                    </div>
                </div>
            </div>
            <div class="service segment">
                <div class="inner-wrap">
                    <h1 class="title">
                        服务范围
                    </h1>
                    <div class="items">
                        <div :key="index" class="item" v-for="(item,index) in solutions.slice(0, 3)">
                            <img :src='getPath(item.litpic)' alt="">
                            <h3 class="subtitle">
                                {{ item.title }}
                            </h3>
                        </div>
                    </div>
                    <router-link active-class="active" class="more" to="/service#">查看更多</router-link>
                </div>
            </div>
            <div class="project segment">
                <div class="inner-wrap">
                    <h1 class="title">
                        工程案例
                    </h1>
                    <div class="items">
                        <div :key="index" class="item" v-for="(item,index) in projects.slice(0, 3)">
                            <img :src='getPath(item.litpic)' alt="">
                            <h3 class="subtitle">
                                {{ item.title }}
                            </h3>
                        </div>
                    </div>
                    <router-link active-class="active" class="more" to="/projects#">查看更多</router-link>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        name: 'Home',
        data() {
            return {
                solutions: [],
                projects: []
            };
        },
        created: function () {
            //为了在内部函数能使用外部函数的this对象，要给它赋值了一个名叫self的变量。
            let self = this;
            $.ajax({
                url: 'http://licheng.xin/admin/news/getSolutions',
                type: 'get',
                data: {},
                dataType: 'json'
            }).then(function (res) {
                //把从json获取的数据赋值给数组
                self.solutions = res;
            }).fail(function () {
                console.log('获取信息失败');
            })

            $.ajax({
                url: 'http://licheng.xin/admin/news/getProjects',
                type: 'get',
                data: {},
                dataType: 'json'
            }).then(function (res) {
                //把从json获取的数据赋值给数组
                self.projects = res;
            }).fail(function () {
                console.log('获取信息失败');
            })
        },
        methods: {
            getPath(imgUrl) {
                return process.env.VUE_APP_HOST_URL + imgUrl;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import url('../assets/css/button.css');
    @import url('../assets/css/fonts.css');
    
    .home {
        width: 100%;
        min-height: 100vh;
        
        .banner {
            background: url('../assets/image/banner.png');
            background-size: cover;
            height: 700px;
            width: inherit;
        }
        
        .about {
            height: 620px;
            width: inherit;
            background: #F5F5F5;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .card {
                max-width: 1200px;
                height: 420px;
                background: #FFFFFF;
                display: flex;
                justify-content: space-between;
                transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                
                &:hover {
                    box-shadow: 4px 8px 16px 0 rgba(55, 51, 51, 0.1);
                }
                
                &:hover .image img {
                    filter: brightness(1.2);
                    -webkit-filter: brightness(1.2);
                    transform: scale(1.05);
                }
                
                &:hover .text h1:after {
                    width: 60px;
                }
                
                .text {
                    width: 640px;
                    padding: 40px 32px;
                    
                    h1 {
                        font-size: 3rem;
                        margin-top: 0;
                        margin-bottom: 20px;
                        position: relative;
                        
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: calc(100% + 20px);
                            height: 2px;
                            width: 30px;
                            background: #202020;
                            transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                        }
                    }
                    
                    p.summary {
                        color: #666666;
                        font-size: 16px;
                        line-height: 2rem;
                        text-align: justify;
                    }
                    
                    .p1 {
                        margin: 42px auto 20px;
                    }
                    
                    .p2 {
                        margin: 0;
                    }
                    
                    a.more {
                        margin-top: 20px;
                    }
                }
                
                .image {
                    width: 560px;
                    height: inherit;
                    overflow: hidden;
                    
                    img {
                        transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                    }
                }
            }
        }
        
        .segment {
            width: inherit;
            background: #FFFFFF;
            
            .inner-wrap {
                display: flex;
                align-items: center;
                flex-direction: column;
                max-width: 1200px;
                margin: 0 auto;
                
                .title {
                    font-size: 36px;
                    margin: 100px 0 32px;
                }
                
                .items {
                    display: flex;
                    justify-content: space-between;
                    width: inherit;
                    margin-bottom: 32px;
                    
                    .item {
                        position: relative;
                        display: block;
                        height: 288px;
                        width: 384px;
                        overflow: hidden;
                        
                        &:nth-child(1),
                        &:nth-child(2) {
                            margin-right: 12px;
                        }
                        
                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            width: 100%;
                            height: 30%;
                            top: 70%;
                            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .1), rgba(0, 0, 0, .4), rgba(0, 0, 0, .7), rgba(0, 0, 0, 1));
                        }
                        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                        }
                        
                        .subtitle {
                            width: 100%;
                            text-align: center;
                            position: absolute;
                            z-index: 99;
                            top: calc(100% - 44px);
                            margin: 0;
                            font-size: 22px;
                            color: #FFFFFF;
                            font-family: "苹方黑体-准-简", "微软雅黑", sans-serif;
                            font-weight: normal;
                        }
                        
                        &:hover img {
                            filter: brightness(1.2);
                            -webkit-filter: brightness(1.2);
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
        
        .project {
            a.more {
                margin-bottom: 100px;
            }
        }
        
        @media only screen and (max-width: 767px) {
            .banner {
                height: 350px;
                background-position: center;
            }
            
            .home-inner-wrap {
                width: 100%;
                
                a.more {
                    margin-top: 12px;
                    font-size: 14px;
                    padding: 7.5px 15px;
                }
                
                .about {
                    height: auto;
                    width: auto;
                    padding: 0 16px 24px;
                    
                    .card {
                        width: 100%;
                        height: auto;
                        margin-top: 24px;
                        flex-direction: column;
                        
                        &:hover .text h1:after {
                            width: 30px;
                        }
                        
                        .text {
                            width: auto;
                            padding: 20px 16px;
                            
                            h1 {
                                font-size: 18px;
                                margin-bottom: 8px;
                                
                                &:after {
                                    height: 1px;
                                    width: 15px;
                                    top: calc(100% + 8px);
                                }
                            }
                            
                            p.summary {
                                font-size: 14px;
                                line-height: 21px;
                            }
                            
                            .p1 {
                                margin-top: 22px;
                            }
                        }
                        
                        .image {
                            width: auto;
                            height: 40vh;
                            background: url("../assets/image/pic_aboutus.png");
                            background-size: cover;
                            
                            img {
                                display: none;
                            }
                        }
                    }
                }
                
                .segment {
                    width: auto;
                    
                    .inner-wrap {
                        width: auto;
                        
                        .title {
                            font-size: 18px;
                            margin: 24px 0 16px;
                        }
                        
                        .items {
                            width: 100%;
                            padding: 0 16px;
                            margin-bottom: 0;
                            box-sizing: border-box;
                            flex-direction: column;
                            
                            .item {
                                width: auto;
                                margin-bottom: 12px;
                                height: 257px;
                                
                                &:nth-child(1),
                                &:nth-child(2) {
                                    margin-right: 0;
                                }
                                
                                .subtitle {
                                    top: calc(100% - 36px);
                                    font-size: 16px;
                                }
                            }
                        }
                        
                        a.more {
                            margin-top: 0;
                        }
                    }
                }
                
                .project {
                    a.more {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
</style>

<template>
    <div class="nav-bar z-section" id="nav-bar" v-bind:class="{ 'scrolled': scrollFlag }">
        <div class="nav-bar-wrap">
            <div class="logo">
                <router-link active-class="active" to="/home">
                    <img alt="" src="../assets/logo.png" width="130">
                </router-link>
            </div>
            <div class="nav-items">
                <router-link active-class="active" to="/home"><span class="underline"></span>首页</router-link>
                <router-link active-class="active" to="/about"><span class="underline"></span>关于我们</router-link>
                <router-link active-class="active" to="/service"><span class="underline"></span>服务范围</router-link>
                <router-link active-class="active" to="/projects"><span class="underline"></span>工程案例</router-link>
                <router-link active-class="active" to="/contact"><span class="underline"></span>联系我们</router-link>
            </div>
            <div @click="open_menu()" class="hamburger-container">
                <img alt="" src="../assets/image/mobile/icon_hamburger.png">
            </div>
            <div class="m-nav-items">
                <div class="m-nav-header">
                    <div @click="close_menu()" class="close">
                        <img alt="" src="../assets/image/mobile/icon_close.png">
                    </div>
                    <div class="m-logo">
                        <router-link active-class="active" to="/home">
                            <img alt="" src="../assets/logo.png">
                        </router-link>
                    </div>
                </div>
                
                <ul @click="close_menu()">
                    <li>
                        <router-link active-class="active" to="/home">首页</router-link>
                    </li>
                    <li>
                        <router-link active-class="active" to="/about">关于我们</router-link>
                    </li>
                    <li>
                        <router-link active-class="active" to="/service#">服务范围</router-link>
                    </li>
                    <li>
                        <router-link active-class="active" to="/projects#">工程案例</router-link>
                    </li>
                    <li>
                        <router-link active-class="active" to="/contact">联系我们</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'

    export default {
        name: "NavBar",
        data() {
            return {
                scrollFlag: false
            }
        },
        watch: {},
        mounted() {
            // if(window.innerWidth > 767) {
            window.addEventListener('scroll', this.debounce(this.handleScroll, 100), true)
            // }
        },
        methods: {
            //防抖
            debounce(fn, delay) {
                let timer = null //借助闭包
                return function () {
                    if (timer) {
                        clearTimeout(timer)
                    }
                    timer = setTimeout(fn, delay) // 简化写法
                }
            },
            handleScroll() {
                let _this = this;
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                //console.log(scrollTop)
                _this.scrollFlag = !!scrollTop;
            },
            open_menu() {
                $('.m-nav-items').css({'right': '0', 'opacity': '1'})
                $('.page-fade-in').css('filter', 'brightness(.5)')
            },
            close_menu() {
                setTimeout(function () {
                    $('.m-nav-items').css({'right': '-100%', 'opacity': '0'})
                    $('.page-fade-in').css('filter', 'brightness(1)')
                }, 120)
            }
        }
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        outline: none;
        blr: expression(this.onFocus = this.blur());
    }
    
    /*去除a标签滚动时奇怪的外框线*/
    a:focus {
        outline: none;
    }
    
    .nav-bar {
        position: fixed;
        top: 0;
        z-index: 1000;
        margin: 0;
        padding: 0;
        height: 6.67rem;
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
        
        .nav-bar-wrap {
            width: 1280px;
            height: inherit;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .hamburger-container {
                display: none;
            }
            
            .m-nav-items {
                display: none;
            }
            
            .logo {
                margin-left: 28px;
            }
            
            .nav-items {
                height: inherit;
                overflow: hidden;
                display: flex;
                align-items: center;
                padding-right: 0;
                
                a {
                    position: relative;
                    color: #ffffff;
                    padding: 29px 2.5rem;
                    font-size: 1.33rem;
                    transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
                    
                    &:hover {
                        background: rgba(255, 255, 255, .2)
                    }
                    
                    /*&:after {
                        content: '';
                        opacity: 0;
                        position: absolute;
                        bottom: -.33rem;
                        left: 50%;
                        transform: translateX(-50%);
                        height: .33rem;
                        width: 1.67rem;
                        background: #FFF;
                        transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                    }
                    
                    &:hover:after {
                        bottom: 0;
                        opacity: 1;
                    }*/
                }
            }
        }
    }
    
    .active {
        opacity: 1;
    }
    
    .active .underline {
        opacity: 1;
    }
    
    .nav-items span {
        display: block;
        opacity: 0;
        position: absolute;
        top: 76px;
        left: 50%;
        transform: translateX(-50%);
        width: 1.67rem;
        height: .33rem;
        background: #FFFFFF;
        transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    
    .scrolled {
        background: #2BA8FF;
        box-shadow: 0 1px 1.67rem .58rem rgba(0, 0, 0, .1);
    }
    
    @media only screen and (max-width: 767px) {
        #nav-bar {
            height: 4rem;
            /*background: #2BA8FF;*/
            /*box-shadow: 0 1px 1.67rem .58rem rgba(0, 0, 0, .1);*/
            
            .logo {
                display: inline-block;
                width: 100%;
                text-align: left;
                padding-left: 5%;
                transform: translate(-2px, 10%);
                margin: 0;
                
                img {
                    width: 80px;
                }
            }
            
            .nav-items {
                display: none;
                position: relative;
            }
            
            .hamburger-container {
                display: block;
                position: absolute;
                width: 1.67rem;
                height: 1.67rem;
                top: 50%;
                right: 4%;
                transform: translateY(-35%);
                
                img {
                    display: block;
                    width: 1.5rem;
                    position: relative;
                }
            }
            
            .m-nav-items {
                display: block;
                position: absolute;
                background: #fff;
                top: 0;
                opacity: 0;
                right: -100%;
                width: 62vw;
                height: 100vh;
                box-shadow: .5rem 0 2rem 8px rgba(0, 0, 0, .1);;
                transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
                
                .m-nav-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 6.67rem;
                    padding: 0 1.25rem;
                    
                    .m-logo {
                        filter: invert(100%);
                        
                        img {
                            display: none;
                            width: 6.67rem;
                        }
                    }
                }
                
                ul {
                    list-style: none;
                    margin-top: 5.5rem;
                    padding-left: 0;
                    
                    li {
                        a {
                            display: block;
                            font-size: 1.33rem;
                            padding-top: 1.83rem;
                            padding-bottom: 1.83rem;
                            padding-left: 4rem;
                            transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
                            
                            &:hover, &:active {
                                background: #2BA8FF;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 360px) {
        #nav-bar {
            .m-nav-items {
                ul {
                    margin-top: 2rem;
                    
                    li {
                        a {
                            padding-top: 1.33rem;
                            padding-bottom: 1.33rem;
                        }
                    }
                }
            }
        }
    }
</style>

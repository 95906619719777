<template>
    <div id="app">
        <nav-bar></nav-bar>
        <router-view/>
        <footer-component></footer-component>
    </div>
</template>

<script>
    export default {
        watch: {
            $route() {
                this.$refs.app.scrollTo(0, 0)
            }
        }
    }
</script>

<style lang="scss">
    @import url('assets/css/fonts.css');
    
    body,
    html {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }
    
    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
        text-decoration: none;
        outline: none;
    }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import('../views/About.vue')
        }
    },
    {
        path: '/service',
        name: 'Service',
        component: function () {
            return import('../views/Service.vue')
        }
    },
    {
        path: '/projects',
        name: 'Projects',
        component: function () {
            return import('../views/Projects.vue')
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: function () {
            return import('../views/Contact.vue')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, saveTop) {
        if (saveTop) {
            return saveTop;
        } else {
            return {x: 0, y: 0}
        }
    }
})

export default router

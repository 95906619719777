import Vue from 'vue'
import App from './App.vue'
import router from './router'
import NavBar from './components/NavBar.vue'
import Footer from "./components/Footer";
//jQuery
import ElementUI from 'element-ui'
import './styles.scss'
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
    ak: '25TaLm8fCPO9fgNFP2uKyhO058YxFNu8'
});
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.component('NavBar', NavBar);
Vue.component('FooterComponent', Footer);

new Vue({
    router,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')

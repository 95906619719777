<template>
    <footer id="#footer">
        <div class="footer-wrap" id="z-section">
            <div class="footer-left">
                <div class="left-text">
                    <h3>联系我们</h3>
                    <p>电话：400-1866-389</p>
                    <p>邮箱：media@licheng.com</p>
                    <p>地址：福建省厦门市集美区百通科技园2号楼301</p>
                </div>
                <p class="copy-right">
                    Copyright©2014-2020 厦门利成智能科技有限公司 版权所有 <a style="color:#2BA8FF" rel="nofollow" target="_blank" href="http://beian.miit.gov.cn/">闽ICP备2020019728号</a>
                </p>
            </div>
            <div class="footer-right">
                <div class="qr-code">
                    <img alt="" src="../assets/image/gzh.png" width="132">
                    <p>微信公众号</p>
                </div>
                <div class="qr-code">
                    <img alt="" src="../assets/image/xcx.jpg" width="132">
                    <p>微信小程序</p>
                </div>
            </div>
        </div>
        <div class="m-footer-wrap">
            <div class="m-footer-top">
                <div class="qr-code">
                    <img alt="" src="../assets/image/gzh.png" width="132">
                    <p>微信公众号</p>
                </div>
                <div class="qr-code">
                    <img alt="" src="../assets/image/xcx.jpg" width="132">
                    <p>微信小程序</p>
                </div>
            </div>
            <div class="m-footer-bottom">
                <div class="bottom-text">
                    <h3>联系我们</h3>
                    <p>电话：400-1866-389</p>
                    <p>邮箱：media@licheng.com</p>
                    <p>地址：福建省厦门市集美区百通科技园2号楼301</p>
                </div>
                <p class="copy-right">
                    Copyright©2014-2020 厦门利成智能科技有限公司 版权所有 <a style="color:#2BA8FF" rel="nofollow" target="_blank" href="http://beian.miit.gov.cn/">闽ICP备2020019728号-4</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterComponent"
    }
</script>

<style lang="scss" scoped>
    @import url('../assets/css/fonts.css');
    
    footer {
        display: block;
        width: 100%;
        height: 367px;
        background: #333333;
        overflow: hidden;
        
        .footer-wrap {
            width: 1200px;
            margin: 0 auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            p {
                font-size: 1rem;
                color: #FFFFFF;
                font-weight: 300;
                font-family: "苹方黑体-细-简", sans-serif;
            }
            
            .footer-left {
                width: 460px;
                
                .left-text {
                    h3 {
                        font-size: 1.33rem;
                        color: #FFFFFF;
                        font-family: "苹方黑体-细-简", sans-serif;
                    }
                    
                    height: 132px;
                    width: inherit;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                }
            }
            
            .footer-right {
                width: 312px;
                display: flex;
                justify-content: space-between;
                
                .qr-code {
                    width: 132px;
                    text-align: center;
                }
            }
        }
        
        //移动端footer
        .m-footer-wrap {
            width: 100vw;
            margin: 0 auto;
            height: 100%;
            display: none;
            position: relative;
            
            .m-footer-top {
                width: auto;
                padding: 2rem 5rem 1rem;
                display: flex;
                justify-content: space-between;
                border-bottom: .5px solid rgba(255, 255, 255, 0.1);
                
                .qr-code {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    width: 100px;
                    
                    img {
                        height: 100px;
                        width: 100px;
                    }
                    
                    p {
                        color: #FFFFFF;
                        font-size: 1rem;
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
            
            .m-footer-bottom {
                
                .bottom-text {
                    margin: 0 auto;
                    padding: 1rem 1.33rem;
                    border-bottom: .5px solid rgba(255, 255, 255, 0.1);
                    
                    p {
                        font-size: 11px;
                        line-height: 1.25rem;
                        color: #FFFFFF;
                        font-weight: 300;
                        font-family: "苹方黑体-细-简", sans-serif;
                    }
                    
                    h3 {
                        font-size: 1.17rem;
                        margin-top: 1rem;
                        color: #FFFFFF;
                        font-weight: normal;
                    }
                }
                
                p.copy-right {
                    font-size: 1rem;
                    display: block;
                    position: absolute;
                    left: -5%;
                    width: 90%;
                    margin: 0 auto;
                    color: #999999;
                    transform: scale(.8) translateY(3px);
                }
            }
        }
    }
    
    @media only screen and (max-width: 887px) {
        footer {
            .footer-wrap {
                display: none;
            }
            
            .m-footer-wrap {
                display: block;
            }
        }
    }
    
    @media only screen and (max-width: 360px) {
        footer {
            .m-footer-wrap {
                transform: scale(.8);
                
                .m-footer-top {
                    padding: 0 4rem 1rem;
                    
                    .qr-code {
                        img {
                            height: 90px;
                            width: 90px;
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (min-height: 590px) and (max-height: 767px) {
        footer {
            .m-footer-wrap {
                transform: scale(.9);
                
                .m-footer-top {
                    padding: 2rem 5rem 1rem;
                    
                    .qr-code {
                        img {
                            height: 100px;
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
</style>
